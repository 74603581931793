import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import * as Config from './helper/config'
import MapPage from './pages/MapPage';
import InTro from "./pages/Intro";

const App: React.FC = () => {
  if (process.env.NODE_ENV !== 'development') {
    console.log = console.warn = console.error = () => {
    };
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          {/* APP */}
          <Route exact path={`${Config.PREFIX_ROUTE}`} component={MapPage}/>
          {/* 구버젼 대응 */}
          <Route exact path={`/app`} component={MapPage}/>
          {/* PC */}
          <Route exact path={`/`} component={InTro}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
